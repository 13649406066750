import Index from "./Pages/Index";
import Request from "./Pages/Request";
import Gardens from "./Pages/Gardens";
import Crops from "./Pages/Crops";
import Calendar from "./Pages/Calendar";
import Reservations from "./Pages/Reservations";
import Updates from "./Pages/Updates";
import SendUpdates from "./Pages/SendUpdates";
import Logout from "./Pages/Logout";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Planting from "./Pages/Planting";
import Impersonate from "./Admin/Impersonate";
import AdminIndex from "./Admin/Index";
import React from "react";
import Team from "./Pages/Team";
import GardenMap from "./Pages/GardenMap";
import Account from "./Pages/Account";
import PlannerV2 from "./Pages/PlannerV2";
import PaymentSuccess from "./Pages/PaymentSuccess";
import PaymentRetry from "./Pages/PaymentRetry";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Welcome from "./Pages/Welcome";
import AcceptInvite from "./Pages/AcceptInvite";
import Profile from "./Pages/Profile";
import CompanyProfile from "./Pages/CompanyProfile";
import SeedDatabase from "./Pages/SeedDatabase";

const routes = [
    {
        path: '/',
        element: <Index/>
    },{
        path: '/request/:company_id',
        element: <Request/>
    },{
        path: '/gardens',
        element: <Gardens/>
    },
    {
        path: "/planner/:id",
        element: <PlannerV2/>
    },
    {
        path: "/map/:id",
        element: <GardenMap/>
    },
    {
        path: "/crops",
        element: <Crops/>
    },
    {
        path: "/calendar",
        element: <Calendar/>
    },
    {
        path: "/reservations",
        element: <Reservations/>
    },
    {
        path: "/updates",
        element: <Updates/>
    },
    {
        path: "/updates/send",
        element: <SendUpdates/>
    },
    {
        path: "/logout",
        element: <Logout />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/register",
        element: <Register />
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "/reset-password/:user_id/:token",
        element: <ResetPassword />
    },
    {
        path: "/garden/:garden_id/bed/:bed_id/planting/:planting_id",
        element: <Planting />
    },
    {
        path: "/admin/impersonate/:user_id",
        element: <Impersonate />
    },
    {
        path: "/admin/",
        element: <AdminIndex />
    },
    {
        path: "/team/accept/:id/:token",
        element: <AcceptInvite />
    },
    {
        path: "/team",
        element: <Team />
    },
    {
        path: "/profile",
        element: <CompanyProfile />
    },
    {
        path: "/account",
        element: <Account />
    },
    {
        path: "/payment/success",
        element: <PaymentSuccess />
    },
    {
        path: "/welcome",
        element: <Welcome />
    },
    {
        path: "/payment/retry",
        element: <PaymentRetry />
    },
    {
        path: "/seeds",
        element: <SeedDatabase />
    },
    {
        path: '/404',
        element: <h1>Sorry, we konden deze pagina niet vinden.</h1>
    },
    {
        path: "/:slug",
        element: <Profile/>
    }
];

export default routes;