import LogUpdateModal from "../LogUpdateModal";
import {Button, Col, Image, Row} from "antd";
import React, {useEffect, useState} from "react";
import api from "../../api";
import dayjs from "dayjs";
import UpdateCard from "../UpdateCard";

export default function PlantingUpdates({planting}) {

    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [updates, setUpdates] = useState([])

    useEffect(() => {
        api.getPlantingUpdates(planting.id).then(setUpdates)
    }, [planting]);

    const onCancel = () => {
        setOpenUpdateModal(false);
        api.getPlantingUpdates(planting.id).then(setUpdates)
    }

    return (
        <div>
            <LogUpdateModal planting={planting} onCancel={onCancel} open={openUpdateModal}></LogUpdateModal>
            <Button type={"primary"} onClick={() => setOpenUpdateModal(true)} className={"mar-b-1"}>Nieuwe update posten</Button>
            {updates.map(update => (
                <Row gutter={12}>
                    <Col key={update.id} md={6} xs={12}>
                        <UpdateCard update={update} />
                    </Col>
                </Row>
            ))}
        </div>
    )
}