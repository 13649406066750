import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Button, Col, DatePicker, Form, InputNumber, Modal, Row, Select, Spin, Tabs} from "antd";
import api from "../../api";
import dayjs from "dayjs";
import CropInfo from "../../Components/CropInfo";
import {useParams} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import PlantingUpdates from "../../Components/PlantingUpdates";
import PlantingPreview from "../../Components/PlantingPreview";
import {PLANTING_STATUS_LABELS} from "../../constants";
import {bedDimensionsToHuman, calculateCropDates, calculateRowsAndPlants} from "../../helpers";

export default function Planting() {
    const navigate = useNavigate();

    const {garden_id, bed_id, planting_id} = useParams();
    const [garden, setGarden] = useState(null);
    const [bed, setBed] = useState(null);
    const [planting, setPlanting] = useState(null);

    const [selectedCrop, setSelectedCrop] = useState(null);
    const [form] = Form.useForm();
    const [amount, setAmount] = useState(0);


    useEffect(() => {
        api.garden(garden_id).then(setGarden);
        api.bed(garden_id, bed_id).then(setBed);
        api.planting(garden_id, bed_id, planting_id).then((p => {
            setPlanting(p);
            setAmount(p.plants_per_row * p.rows)
        }));
    }, [garden_id, bed_id, planting_id]);

    function deletePlanting() {
        Modal.confirm({
            title: 'Weet je het zeker?',
            content: 'Weet je zeker dat je deze aanplant wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            onOk: () => {
                api.removePlanting(planting.id).then(() => {
                    navigate(`/planner/${garden.id}`)
                });
            },
            okText: 'Ja, verwijderen',
            cancelText: 'Annuleren'
        });


    }


    function calculateDates() {
        const cropDates = calculateCropDates(selectedCrop, form.getFieldValue('sow_at'))
        setPlanting({
            ...planting,
            sow_at: cropDates.sow_at.format('YYYY-MM-DD'),
            transplant_at: cropDates.transplant_at.format('YYYY-MM-DD'),
            harvest_at: cropDates.harvest_at.format('YYYY-MM-DD')
        })
    }

    function calculateAmounts() {
        if (!selectedCrop || !bed) {
            return
        }

        const {rows, plants_per_row} = calculateRowsAndPlants(bed, selectedCrop)
        setAmount(rows * plants_per_row)
        setPlanting({...planting, rows, plants_per_row: plants_per_row})

    }

    function onAmountChange() {
        const rows = form.getFieldValue('rows');
        const plants_per_row = form.getFieldValue('plants_per_row');
        setPlanting({...planting, rows, plants_per_row})
        setAmount(rows * plants_per_row)
    }

    useEffect(() => {
        if (!planting || !form) {
            return
        }

        setSelectedCrop(planting.crop)
        form.setFieldValue('bed_id', planting.bed_id)
        form.setFieldValue('status', planting.status)

        if (planting.sow_at) {
            form.setFieldValue('sow_at', dayjs(planting.sow_at))
        }
        if (planting.transplant_at) {
            form.setFieldValue('transplant_at', dayjs(planting.transplant_at))
        }
        if (planting.harvest_at) {
            form.setFieldValue('harvest_at', dayjs(planting.harvest_at))
        }


        form.setFieldValue('crop_id', planting.crop_id)
        form.setFieldValue('rows', planting.rows)
        form.setFieldValue('plants_per_row', planting.plants_per_row)

        // form.setFieldValue('unit_price', planting.unit_price)
        // form.setFieldValue('expected_yield', planting.expected_yield)
        // form.setFieldValue('expected_yield_unit', planting.expected_yield_unit)


    }, [planting, form]);


    const handleBedChange = (id) => {
        const value = garden.beds.find(bed => bed.id === id);
        if (!value) {
            return;
        }
        setBed(value);
        form.setFieldsValue({bed_id: value.id});
    }

    function save(values) {

        const plantingData = {
            bed_id: values.bed_id,
            crop_id: values.crop_id,
            status: values.status,
            rows: values.rows,
            plants_per_row: values.plants_per_row,
            sow_at: values.sow_at ? values.sow_at.format('YYYY-MM-DD') : null,
            transplant_at: values.transplant_at ? values.transplant_at.format('YYYY-MM-DD') : values.sow_at.format('YYYY-MM-DD'),
            harvest_at: values.harvest_at.format('YYYY-MM-DD'),
            // expected_yield: values.expected_yield,
            // expected_yield_unit: values.expected_yield_unit,
            // unit_price: values.unit_price
        }

        if (planting) {
            api.updatePlanting(planting.id, plantingData).then(() => {
                Modal.success({
                    title: 'De aanplant is bijgewerkt',
                    content: 'We sturen je nu terug naar het overzicht van de tuin',
                    onOk: () => navigate(`/planner/${garden.id}`)
                });
            }).catch(() => {
                Modal.error({
                    title: 'Er is iets misgegaan',
                    content: 'We konden de aanplant niet opslaan. Er is iets niet correct ingevuld. Controleer of alle velden zijn ingevuld.'
                })
            });
            return;
        }

        api.addPlanting(plantingData).then(() => {
            form.resetFields();
            setSelectedCrop(null);
        });
    }

    if (!bed || !garden || !planting || !selectedCrop) {
        return (<Spin></Spin>)
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const bedSelectOptions = garden.beds.map((bed) => {
        return {
            value: bed.id,
            label: bed.name,
        };
    });

    return (
        <div>
            <Row>
                <Col span={24} style={{marginBottom: 24}}>
                    <Button onClick={() => navigate(`/planner/${garden.id}`)} size={"large"}>
                        <ArrowLeftOutlined/> Terug naar {garden.name}
                    </Button>
                </Col>
            </Row>
            <Form
                layout="horizontal"
                labelCol={{xs: {span: 12}, sm: {span: 8}, md: {span: 6}}}
                wrapperCol={{xs: {span: 12}, sm: {span: 16}, md: {span: 18}}}
                form={form}
                onFinish={save}
            >
                <Row gutter={18}>
                    <Col span={12}>
                        <Form.Item>
                            <Button danger onClick={() => deletePlanting()}>
                                Verwijderen
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Opslaan
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <h1>{planting.rows * planting.plants_per_row}x {planting.crop.name} - {planting.crop.variety}</h1>

                <Tabs defaultActiveKey="1" items={[
                    {
                        key: '1', label: 'Gewas', forceRender: true, children: (
                            <>

                                <CropInfo crop={selectedCrop}/>
                                <h1>Status en plek</h1>

                                <Row gutter={18}>
                                    <Col md={12} xs={24}>
                                        <Form.Item name="bed_id" label="Bed" labelAlign="left">
                                            <Select onChange={handleBedChange}
                                                    showSearch
                                                    placeholder="Selecteer een bed"
                                                    optionFilterProp="children"
                                                    filterOption={filterOption}
                                                    options={bedSelectOptions}
                                            >
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="status" label="Status" labelAlign="left">
                                            <Select>
                                                {Object.keys(PLANTING_STATUS_LABELS).map((s) => (
                                                    <Select.Option key={s}
                                                                   value={s}>{PLANTING_STATUS_LABELS[s]}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <h1>Timing</h1>
                                <Row gutter={18}>
                                    <Col md={12} xs={24}>
                                        <>
                                            <Form.Item name="sow_at" label="Zaaidatum" labelAlign="left">
                                                <DatePicker
                                                    inputMode={"date"}
                                                    placeholder="Selecteer een zaaidatum"
                                                    style={{width: "100%"}}
                                                />
                                            </Form.Item>
                                            <Form.Item name="transplant_at" label="Verplantdatum" labelAlign="left">
                                                <DatePicker
                                                    placeholder="Selecteer een verplantdatum"
                                                    style={{width: "100%"}}
                                                    inputMode={"date"}
                                                />
                                            </Form.Item>
                                            <Form.Item name="harvest_at" label="Oogstdatum" labelAlign="left">
                                                <DatePicker
                                                    placeholder="Select oogst datum"
                                                    style={{width: "100%"}}
                                                    inputMode={"date"}
                                                />
                                            </Form.Item>
                                        </>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Button onClick={calculateDates}>Bereken datums vanaf zaaidatum</Button>
                                        <p style={{marginTop: '24px'}}>
                                            Als je geen zaaidatum hebt ingevuld, wordt de eerstmogelijke datum gebruikt.
                                        </p>
                                    </Col>
                                </Row>

                                <h1>Aantal rijen en planten</h1>

                                <Row gutter={18}>
                                    <Col md={12} xs={24}>

                                        <Form.Item name="rows" label="Aantal rijen in bed" labelAlign="left">
                                            <InputNumber onChange={onAmountChange}
                                                         placeholder={"Enter the number of rows"}
                                                         style={{width: "100%"}}
                                                         inputMode={"numeric"}
                                            />
                                        </Form.Item>

                                        <Form.Item name="plants_per_row" label="Aantal planten per rij"
                                                   labelAlign="left">
                                            <InputNumber onChange={onAmountChange}
                                                         placeholder={"Enter the number of plants per row"}
                                                         style={{width: "100%"}}
                                                         inputMode={"numeric"}
                                            />
                                        </Form.Item>

                                        <div id={"planting-preview"}>
                                            <PlantingPreview bedWidth={bed.length} bedLength={bed.width}
                                                             numRows={planting.rows}
                                                             plantsPerRow={planting.plants_per_row}></PlantingPreview>
                                        </div>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Button type={"default"} onClick={calculateAmounts}>
                                            Bereken aantal rijen en planten per rij
                                        </Button>
                                        {(planting.rows && planting.plants_per_row) && (
                                            <p style={{marginTop: '18px'}}>
                                                Dit gewas heeft een rijafstand van {selectedCrop?.row_distance}cm
                                                en een plantafstand van {selectedCrop?.plant_distance}cm.
                                                <br/>De afmetingen van dit bed
                                                zijn: <strong>{bedDimensionsToHuman(bed)}</strong>
                                                <br/>Dit betekent dat er:&nbsp;
                                                {planting.rows === 1 && (
                                                    <>
                                                        <br/><strong>1 rij</strong> in dit bed past
                                                    </>
                                                )}

                                                {planting.rows > 1 && (
                                                    <>
                                                        <br/><strong>{planting.rows} rijen</strong> in dit bed passen
                                                    </>
                                                )}

                                                {planting.plants_per_row > 1 && (
                                                    <>
                                                        <br/><strong>{planting.plants_per_row} planten</strong> per
                                                        rij passen,
                                                    </>
                                                )}
                                                <br/>en dus <strong>{amount} planten</strong> in totaal in dit bed
                                                passen.
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>


                                    </Col>
                                </Row>
                            </>
                        )
                    },
                    {
                        key: '4', label: 'Updates', forceRender: true, children: (
                            <Row>
                                <Col md={24}>
                                    <PlantingUpdates planting={planting}></PlantingUpdates>
                                </Col>
                            </Row>
                        )
                    },
                    // {
                    //     key: '5',
                    //     label: 'To-do\'s',
                    //     children: (
                    //         <Row>
                    //             <Col md={24}>
                    //                 <Form.Item>
                    //                     <Checkbox checked={planting.sown}
                    //                               onChange={() => toggleCheckbox('sown', planting)}/> Gezaaid
                    //                 </Form.Item>
                    //                 <Form.Item>
                    //                     <Checkbox checked={planting.transplanted}
                    //                               onChange={() => toggleCheckbox('transplanted', planting)}/> Uitgeplant
                    //                 </Form.Item>
                    //                 <Form.Item>
                    //                     <Checkbox checked={planting.harvested}
                    //                               onChange={() => toggleCheckbox('harvested', planting)}/> Geoogst
                    //                 </Form.Item>
                    //             </Col>
                    //         </Row>
                    //     )
                    // }
                    // {
                    //     key: '5', label: 'Verkoopprijzen', forceRender: true, children: (
                    //
                    //         <>
                    //             <Form.Item name="expected_yield" label="Verwachtte opbrengst"
                    //                        labelAlign="left">
                    //                 <InputNumber placeholder={"Aantal"}
                    //                              style={{width: "100%"}}/>
                    //             </Form.Item>
                    //             <Form.Item name="expected_yield_unit" label="Eenheid"
                    //                        labelAlign="left">
                    //                 <Input placeholder={"Eenheid (bijv per kg, per stuk etc)"}
                    //                        style={{width: "100%"}}/>
                    //             </Form.Item>
                    //             <Form.Item name="unit_price" label="Prijs per eenheid"
                    //                        labelAlign="left">
                    //                 <InputNumber placeholder={"Prijs per eenheid"}
                    //                              t
                    //                              style={{width: "100%"}}/>
                    //             </Form.Item>
                    //         </>
                    //     )
                    // },
                ]}>
                </Tabs>


            </Form>
        </div>
    )
}