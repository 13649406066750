import React, {useEffect} from "react";
import {Spin} from "antd";
import {useParams} from "react-router-dom";
import api from "../../api";


export default function Impersonate() {
    const {user_id} = useParams();

    useEffect(() => {
        const admin_token = localStorage.getItem('admin_token');
        if(user_id === "stop" && admin_token) {
            localStorage.setItem('token', admin_token);
            localStorage.removeItem('admin_token');
            setTimeout(() => {
                window.location.href = '/admin';
            }, 1000);
            return;
        }

        if(user_id === "stop") {
            return
        }

        // Put the current token in the admin_token key in local storage for switching back
        const current_token = localStorage.getItem('token')
        if(!api.isAdmin()) {
            // Already logged in as a user, so need to set the admin token
            // in the token, so we can get the new user token
            localStorage.setItem('token', admin_token);

        }
        localStorage.setItem('admin_token', current_token);
        api.impersonate(user_id).then((response) => {
            if(response.token) {
                localStorage.setItem('token', response.token);
                window.location.href = '/';
            }
        });
    }, [user_id]);
    return (
        <Spin>
            Impersonating...
        </Spin>
    )
}