import {Modal, Form, Col, Row, Button, Input, Spin, message} from "antd";
import React, {useState, useEffect} from "react";
import api from "../../api";
import {calculateCropDates, calculateRowsAndPlants} from "../../helpers";
import {GENERIC_ERROR_MESSAGE} from "../../constants";
import {useNavigate} from "react-router-dom";

export default function PlantingModal({open, bed, onClose, onPlantingAdded}) {

    const [crops, setCrops] = useState([]);
    const [form] = Form.useForm();
    const [searchingForCrops, setSearchingForCrops] = useState(false);
    const [step, setStep] = useState(null);
    const [addingCrop, setAddingCrop] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        api.crops().then(setCrops);
    }, [open]);

    function save(values) {
        api.addPlanting(values).then((planting) => {
            onPlantingAdded(planting)
            onCancel()
        })
    }

    function handleSearch() {
        setSearchingForCrops(true)
        setStep("Gewassen zoeken...")
        api.search(form.getFieldValue('query')).then(setCrops).finally(() => {
            setSearchingForCrops(false)
            setStep(null)
        })
    }

    function planCrop(crop) {
        setAddingCrop(true)
        if (crop.id) {
            const rowsAndPlants = calculateRowsAndPlants(bed, crop)
            const cropDates = calculateCropDates(crop, null)
            setStep("We voegen het gewas toe aan je bed...")

            save({bed_id: bed.id, crop_id: crop.id, ...rowsAndPlants, ...cropDates})
            return
        }

        setAddingCrop(true)
        setStep("Een moment geduld, we halen alle gegevens op van het geselecteerde gewas...")
        api.getCropInfo(crop).then((crop) => {
            setStep("Gewas toevoegen aan jouw account...")

            api.createCrop(crop).then((c) => {
                const rowsAndPlants = calculateRowsAndPlants(bed, c)
                const cropDates = calculateCropDates(c, null)

                save({bed_id: bed.id, crop_id: c.id, ...rowsAndPlants, ...cropDates})
            }).catch((error) => {
                if (error.response.data.error === "CropLimitExceededException") {
                    Modal.error({
                        title: "Je hebt het maximum aantal gewassen bereikt",
                        okText: "Ga naar account instellingen",
                        onOk: () => navigate('/account'),
                        content: (
                            <div>
                                <p>Je hebt het maximum aantal gewassen bereikt voor je account.</p>
                                <p>Wil je meer gewassen toevoegen? Upgrade dan.</p>
                            </div>
                        )
                    })
                    return
                }

                message.info(GENERIC_ERROR_MESSAGE)
            }).finally(() => {
                setStep(null)
                setAddingCrop(false)
            })
        }).catch(() => {
            setStep(null)
            setAddingCrop(false)
        })
    }

    function onCancel() {
        setSearchingForCrops(false)
        setAddingCrop(false)
        setStep(null)
        onClose()
    }

    return (
        <Modal open={open} footer={null} onCancel={onCancel}>
            <h1>Nieuw gewas plannen</h1>
            <Form
                layout="vertical"
                form={form}
            >
                <Row gutter={[18, 18]}>
                    <Col span={18}>
                        <Form.Item label="Zoek naar (nieuwe) gewassen" name="query">
                            <Input style={{width: '100%'}} onPressEnter={handleSearch} disabled={searchingForCrops}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className={"text-right"}>
                        <Form.Item label="&nbsp;">
                            <Button onClick={() => handleSearch()} type={"primary"}>Zoeken</Button>
                        </Form.Item>
                    </Col>
                </Row>

                {(searchingForCrops || addingCrop) ? (
                    <div className={"text-center"}>
                        <Spin/>
                        <br/>{step}
                    </div>
                ) : null}

                {(!addingCrop && !searchingForCrops) && crops.slice(0, 10).map((crop, i) => {
                    return (
                        <Row key={crop.name + '-' + i} style={{marginBottom: '18px'}}>
                            <Col span={4}>
                                <img src={crop.image} alt={crop.name} style={{maxWidth: '48px', maxHeight: '48px'}}/>
                            </Col>
                            <Col span={15}>
                                <h4>{crop.name} - {crop.variety}</h4>
                                {crop.supplier ? <small>{crop.supplier}</small> : null}
                            </Col>
                            <Col label={crop.name} name="crop_id" span={5} className={"text-right"}>
                                <Button onClick={() => planCrop(crop)}>
                                    Inplannen
                                </Button>
                            </Col>
                        </Row>
                    )
                })}
            </Form>
        </Modal>
    )
}