import React from 'react';
import {useEffect, useState} from "react";
import api from "../../api";
import {Button, Col, Modal, Row, Table} from "antd";
import {useNavigate} from "react-router-dom";

export default function Reservations() {

    const [crops, setCrops] = useState([])
    const [cropCustomers, setCropCustomers] = useState([])
    const [showingCrop, setShowingCrop] = useState(false)
    const [showCustomersModal, setShowCustomersModal] = useState(false)
    const navigate =  useNavigate();


    const [company, setCompany] = useState();


    useEffect(() => {
        api.getCropsWithReservations().then(setCrops)
        api.getMyCompany().then(setCompany)
    }, [])

    const showCustomers = (crop) => {
        setShowingCrop(crop)
        api.getCropReservationCustomers(crop.id)
            .then(setCropCustomers)
            .finally(() => setShowCustomersModal(true))
    }

    const reservations = crops.sort((a, b) => {
        return b.reservations.length - a.reservations.length;
    });

    return (
        <div>


            <Row>
                <Col md={12} xs={24}><h1>Aanvragen</h1></Col>
                <Col md={12} xs={24} className={"text-right"}>
                    <Button onClick={() => navigate("/request/" + company.id)} type={"primary"} size={"large"}>Open mijn aanvraag pagina</Button>
                </Col>
            </Row>



            <Modal width={"80%"}
                   open={showCustomersModal}
                   onOk={() => setShowCustomersModal(false)}
                   onCancel={() => setShowCustomersModal(false)}>
                <h2>Aanvragers voor {showingCrop.name} '{showingCrop.variety}'</h2>
                <Table dataSource={cropCustomers.map(r => {
                    return {
                        key: r.id,
                        name: r.customer.name,
                        email: r.customer.email,
                        phone: r.customer.phone,
                    }
                })} columns={[
                    {title: 'Naam', dataIndex: 'name', key: 'name'},
                    {title: 'Email', dataIndex: 'email', key: 'email'},
                    {title: 'Telefoon', dataIndex: 'phone', key: 'phone'},
                ]} />
            </Modal>
            {reservations.map(crop => {
                return (
                    <Row key={crop.id} style={{paddingBottom: "16px"}}>
                        <Col md={2} xs={4}>
                            <img src={crop.image} style={{maxHeight: '48px'}}/>
                        </Col>
                        <Col md={18} xs={20}>
                            <h2 style={{lineHeight: '48px'}}>{crop.reservations.length}x {crop.name} '{crop.variety}'</h2>

                        </Col>
                        <Col md={2}>
                            <div>
                                <Button style={{margin: "4px"}} onClick={() => showCustomers(crop)}>
                                    Toon aanvragers
                                </Button>
                            </div>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}