import api from "../../api";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Drawer,
    Form, Modal,
    Row,
    Select,
    Spin,
    Tabs,
    Tooltip
} from "antd";
import PlantingModal from "../../Components/PlantingModal";
import BedModal from "../../Components/BedModal";
import dayjs from "dayjs";
import "./style.css"
import {
    PlusOutlined,
    SettingFilled,
    WarningOutlined
} from "@ant-design/icons";
import {PLANTING_STATUS, PLANTING_STATUS_LABELS} from "../../constants";

import {bedDimensionsToHuman, filterPlanting} from "../../helpers";
import {isMobile} from "react-device-detect";

export default function PlannerV2() {

    const groupByPeriodLocal = localStorage.getItem('groupByPeriod')
    const hideHarvestedLocal = localStorage.getItem('hideHarvested')
    const compactModeLocal = localStorage.getItem('compactMode')
    const defaultDateRangeLocal = localStorage.getItem('defaultDateRange')
    const navigate = useNavigate();

    let {id} = useParams();
    const [garden, setGarden] = useState(null)
    const [plantingModalOpen, setPlantingModalOpen] = useState(false)
    const [selectedBed, setSelectedBed] = useState(null)
    const [selectedPlanting, setSelectedPlanting] = useState(null)
    const [bedModalOpen, setBedModalOpen] = useState(false)
    const [allGardens, setAllGardens] = useState([])
    const [defaultDateRange, setDefaultDateRange] = useState(defaultDateRangeLocal ?? "next_12_months")
    const [groupByPeriod, setGroupByPeriod] = useState(groupByPeriodLocal ?? "month")
    const [hideHarvested, setHideHarvested] = useState(hideHarvestedLocal ? hideHarvestedLocal === "true" : true)
    const [compactMode, setCompactMode] = useState(hideHarvestedLocal ? compactModeLocal === "true" : true)
    const [showDisplayDrawer, setShowDisplayDrawer] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().startOf('month'))
    const [endDate, setEndDate] = useState(dayjs().endOf('year'))
    const [periods, setPeriods] = useState([])


    function openModal(bed) {
        setSelectedBed(bed)
        setBedModalOpen(true)
    }

    function loadGarden() {
        api.garden(id).then(setGarden)
        api.gardens().then(setAllGardens)
        setPreSelectedDateRange(defaultDateRange)
        if (groupByPeriod === "month" || groupByPeriod === "week") {
            setPeriods(generatePeriods(startDate, endDate, groupByPeriod))
        }
    }

    function showPlantingModal(bed) {
        setSelectedBed(bed)
        setPlantingModalOpen(true)
    }

    function onClose() {
        setPlantingModalOpen(false);
        setBedModalOpen(false);
        setSelectedPlanting(null);
        setSelectedBed(null);
        loadGarden();
    }

    function generatePeriods(start, end, groupBy) {
        let current = start;
        if (groupBy === "text" || groupBy === "none") {
            return []
        }
        const groups = [];

        // Helper function to determine next grouping point
        const getNextGroupStart = (currentDate, groupBy) => {
            if (groupBy === 'week') {
                return currentDate.startOf('week').add(1, 'week');
            } else if (groupBy === 'month') {
                return currentDate.startOf('month').add(1, 'month');
            } else if (groupBy === 'year') {
                return currentDate.startOf('year').add(1, 'year');
            } else {
                throw new Error("Invalid groupBy value. Use 'week' or 'month'.");
            }
        };

        while (current.isBefore(end)) {
            const groupStart = current.startOf(groupBy);
            groups.push(groupStart);
            current = getNextGroupStart(current, groupBy);
        }

        return groups;
    }


    useEffect(() => {
        loadGarden()
    }, []);

    useEffect(() => {
        loadGarden()
    }, [id]);


    useEffect(() => {
        setPeriods(generatePeriods(startDate, endDate, groupByPeriod))
    }, [startDate, endDate, groupByPeriod]);


    if (garden === null) {
        return (<Spin/>)
    }

    const periodToHuman = (period) => {
        if (groupByPeriod === "month") {
            return period.format("MMM")
        }
        if (groupByPeriod === "week") {
            return period.format("w")
        }
        if (groupByPeriod === "year") {
            return period.format("YYYY")
        }
        return period.toString()
    }

    function inDateRange(start, end, period) {
        // if (groupByPeriod === "month") {
        //     return period.month() >= start.month() && period.month() <= end.month()
        // }
        // if (groupByPeriod === "week") {
        //     return period.week()  >= start.week() && period.week() <= end.week()
        // }
        //
        // const start_unix = start.unix()
        // const end_unix = end.unix()
        //
        if (groupByPeriod === "year") {
            return period.year() >= start.year() && period.year() <= end.year()
        }
        return period.unix() >= start.unix() && period.unix() <= end.unix()
    }

    function inBed(planting, period) {
        return inDateRange(dayjs(planting.transplant_at), dayjs(planting.harvest_at), period)
    }

    function inSeed(planting, period) {
        return inDateRange(dayjs(planting.sow_at), dayjs(planting.transplant_at), period)
    }

    function isNotOnTrack(planting) {
        if (dayjs().unix() > dayjs(planting.harvest_at).unix() && planting.status !== PLANTING_STATUS.HARVESTED) {
            return "Oogsttijd overschreden"
        }

        if (dayjs().unix() > dayjs(planting.transplant_at).unix() && planting.status === PLANTING_STATUS.SOWN) {
            return "Uitplanttijd overschreden"
        }

        if (dayjs().unix() > dayjs(planting.sow_at).unix() && planting.status === PLANTING_STATUS.PLANNED) {
            return "Zaaitijd overschreden"
        }
        return false
    }

    function setHideHarvestedLocal(e) {
        const checked = e.target.checked
        localStorage.setItem('hideHarvested', checked)
        setHideHarvested(checked)
    }

    function setCompactModeLocal(e) {
        const checked = e.target.checked
        localStorage.setItem('compactMode', checked)
        setCompactMode(checked)
    }

    function setGroupBy(period) {
        localStorage.setItem('groupByPeriod', period)
        setGroupByPeriod(period)
        setPeriods(generatePeriods(startDate, endDate, period))
    }

    function setPreSelectedDateRange(selection) {
        localStorage.setItem('defaultDateRange', selection)
        setDefaultDateRange(selection)
        if (selection === "today_end_of_year") {
            setStartDate(dayjs())
            setEndDate(dayjs().endOf('year'))
        }
        if (selection === "start_of_year_today") {
            setStartDate(dayjs().startOf('year'))
            setEndDate(dayjs())
        }
        if (selection === "this_year") {
            setStartDate(dayjs().startOf('year'))
            setEndDate(dayjs().endOf('year'))
        }
        if (selection === "next_year") {
            setStartDate(dayjs().startOf('year').add(1, 'year'))
            setEndDate(dayjs().endOf('year').add(1, 'year'))
        }
        if (selection === "next_12_months") {
            setStartDate(dayjs())
            setEndDate(dayjs().add(11, 'months'))
        }
        if (selection === "next_3_months") {
            setStartDate(dayjs())
            setEndDate(dayjs().add(3, 'months'))
        }
    }

    function navigateToPlanting(bed, planting) {
        navigate(`/garden/${garden.id}/bed/${bed.id}/planting/${planting.id}`)
    }


    const activeTabKey = `garden_tab_${allGardens.findIndex(g => g.id === parseInt(id))}`;
    return (
        <div className={compactMode ? "planner-compact" : 'planner-large'}>
            <Tabs
                activeKey={activeTabKey}
                defaultActiveKey="garden_tab_1"
                type="card"
                size={"large"}
                style={{
                    marginBottom: 32,
                }}
                onTabClick={(key) => {
                    const id = key.replace('garden_tab_', '')
                    navigate(`/planner/${id}`)
                }}
                items={allGardens.map(g => ({
                    key: `garden_tab_${g.id}`,
                    label: g.name
                }))}
            />
            <Drawer open={showDisplayDrawer} onClose={() => setShowDisplayDrawer(false)}>
                <Form.Item label={"Datums tonen"}>
                    <Select size={"small"}
                            defaultValue={groupByPeriod}
                            onChange={(e) => setGroupBy(e)}
                            options={[
                                {label: "Niet", value: "none"},
                                {label: "Als tekst", value: "text"},
                                {label: "Per week", value: "week"},
                                {label: "Per maand", value: "month"},
                                // {label: "Per jaar", value: "year"},
                            ]}
                    >
                    </Select>
                </Form.Item>
                <Form.Item help={"Als je dit aanvinkt, zal alles wat je al als geoogst hebt gemarkeerd worden verborgen."}>
                    <Checkbox checked={hideHarvested} onChange={setHideHarvestedLocal}></Checkbox> Verberg geoogst
                </Form.Item>
                <Form.Item help={"Deze optie zorgt ervoordat alles compacter wordt weergegeven, dit is vooral handig als je heel veel bedden in 1 tuin hebt! Deze optie werkt alleen op desktop, niet op mobiele apparaten"}>
                    <Checkbox disabled={isMobile} checked={compactMode} onChange={setCompactModeLocal}></Checkbox> Compacte modus
                </Form.Item>
            </Drawer>
            <Row>
                <Col xs={12} md={12}>
                    <Select defaultValue={defaultDateRange}
                            style={{width: '100%'}}
                            onChange={(e) => setPreSelectedDateRange(e)}
                            options={[
                                {label: "Vandaag tot einde jaar", value: "today_end_of_year"},
                                {label: "Begin dit jaar tot vandaag", value: "start_of_year_today"},
                                {label: "Dit jaar", value: "this_year"},
                                {label: "Volgend jaar", value: "next_year"},
                                {label: "Komende 3 maanden", value: "next_3_months"},
                                {label: "Komende 12 maanden", value: "next_12_months"}
                            ]}></Select>

                </Col>
                <Col xs={12} md={12} style={{textAlign: "right"}}>
                    <Button onClick={() => setShowDisplayDrawer(!showDisplayDrawer)}>
                        <SettingFilled/> Weergave
                    </Button>
                </Col>

            </Row>
            <div style={{height: '20px'}}></div>


            <PlantingModal
                open={plantingModalOpen}
                onClose={onClose}
                bed={selectedBed}
                planting={selectedPlanting}
                onPlantingAdded={(planting) => {
                    if (dayjs(planting.sow_at).unix() > endDate.unix()) {
                        Modal.info({
                            title: 'Let op!',
                            content: (
                                `De aanplant is automatisch gepland in de toekomst. Je ziet nu niks in de planner, want de zaaidatum valt buiten de geselecteerde periode. `
                                + `Je kunt de tijdlijn van de planner aanpassen door (bijvoorbeeld) de weergave op de komende 12 maanden te zetten.`
                            )
                        })
                    }
                    onClose()
                }}
            ></PlantingModal>

            <BedModal garden={garden} onCancel={onClose} open={bedModalOpen} bed={selectedBed}></BedModal>

            <h1 className={"garden-title"}>{garden.name} {startDate.year() === endDate.year() ? startDate.year() : `${startDate.year()} - ${endDate.year()}`}</h1>

            {garden.beds.length === 0 && (
                <>
                    <Alert
                        description={"Je moestuin heeft nog geen bedden, druk op de onderstaande knop om je eerste bed toe tevoegen!"}></Alert>
                </>
            )}

            {garden.beds.map((bed) => {
                return (
                    <div className={"bed-wrapper"} key={`bed${bed.id}`}>
                        <Row>
                            <Col xs={12} md={compactMode ? 3 : 12}>
                                <p className={"bed-title"}>{bed.name} ({bedDimensionsToHuman(bed)})</p>
                            </Col>
                            <Col xs={12} md={compactMode ? 0 : 12} className={"bed-buttons"}>
                                <Button onClick={() => openModal(bed)} size={compactMode ? 'small' : 'normal'}>
                                    <SettingFilled/>
                                </Button>
                                <Button style={{marginLeft: '8px'}} type="default"
                                        size={compactMode ? 'small' : 'normal'}
                                        onClick={() => showPlantingModal(bed)}>
                                    <PlusOutlined/>
                                </Button>
                            </Col>
                            <Col md={compactMode ? 19 : 24}>
                                {bed.plantings.filter((p) => filterPlanting(p, endDate, hideHarvested)).length === 0 && (
                                    <div className={"add-first-planting-button"}>
                                        Klik op de
                                        <Button style={{margin: '0 8px'}} type="default" size={"small"}
                                                onClick={() => showPlantingModal(bed)}>
                                            <PlusOutlined/>
                                        </Button>
                                        knop om een plant toe te voegen aan dit bed
                                    </div>
                                )}
                                {bed.plantings.filter((p) => filterPlanting(p, endDate, hideHarvested)).map(planting => (
                                    <div key={`bed-${bed.id}-planting-${planting.id}`} className={"planting-wrapper"}>
                                        <Row>
                                            <Col xs={4} md={1} onClick={() => navigateToPlanting(bed, planting)}>
                                                <img alt={planting.crop.name} className={"planting-image"}
                                                     src={planting.crop.image}/>
                                            </Col>
                                            <Col xs={12} md={5} onClick={() => navigateToPlanting(bed, planting)}>
                                                <span className={"planting-name"}>
                                                    {planting.plants_per_row * planting.rows}x {planting.crop.name}
                                                </span>
                                                <span className={"on-track-warning"}>
                                                    {isNotOnTrack(planting) && (
                                                        <Tooltip title={isNotOnTrack(planting)}>
                                                            <WarningOutlined/>
                                                        </Tooltip>
                                                    )}
                                                </span>
                                            </Col>
                                            <Col xs={7} md={2}>
                                                <div className={"status-dropdown"}>
                                                    <Select size={"small"} defaultValue={planting.status}
                                                            onChange={(e) => {
                                                                api.updatePlanting(planting.id, {
                                                                    ...planting,
                                                                    status: e
                                                                }).then(() => {
                                                                    loadGarden()
                                                                })
                                                            }}
                                                            onClick={(e) => e.preventDefault()}>
                                                        {Object.keys(PLANTING_STATUS_LABELS).map(status => (
                                                            <Select.Option key={status}
                                                                           value={status}>{PLANTING_STATUS_LABELS[status]}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </Col>
                                            {(groupByPeriod === "text") && (
                                                <Col md={12} xs={24} className={"text-center"}>
                                                    <Row onClick={() => navigateToPlanting(bed, planting)}>
                                                        <Col span={8} className={"planting-date"}>
                                                            <label>Zaaien</label>
                                                            <div>{dayjs(planting.sow_at).format("DD MMM YYYY")}</div>
                                                        </Col>
                                                        <Col span={8} className={"planting-date"}>
                                                            <label>Verplanten</label>
                                                            <div>{dayjs(planting.transplant_at).format("DD MMM YYYY")}</div>
                                                        </Col>
                                                        <Col span={8} className={"planting-date"}>
                                                            <label>Oogsten</label>
                                                            <div>{dayjs(planting.harvest_at).format("DD MMM YYYY")}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                            {(periods.length > 0) && (
                                                <Col xs={24} md={16} style={{overflowX: 'auto'}}
                                                     onClick={() => navigateToPlanting(bed, planting)}>
                                                    <div className={"periods-wrapper"}>
                                                        {periods.map(period => (
                                                            <div className={
                                                                inBed(planting, period) ? "period-block period-in-bed" : inSeed(planting, period) ? "period-block period-in-seed" : "period-block"
                                                            } key={periodToHuman(period)}>
                                                                {periodToHuman(period)}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>

                                    </div>
                                ))}
                            </Col>

                            <Col xs={0} md={compactMode ? 2 : 0} className={"bed-buttons"}>
                                <Button onClick={() => openModal(bed)} size={compactMode ? 'small' : 'normal'}>
                                    <SettingFilled/>
                                </Button>
                                <Button style={{marginLeft: '8px'}} type="default"
                                        size={compactMode ? 'small' : 'normal'}
                                        onClick={() => showPlantingModal(bed)}>
                                    <PlusOutlined/>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )
            })}

            <div style={{margin: '1rem 0'}}>
                <Button onClick={() => setBedModalOpen(true)}>Nieuw bed toevoegen</Button>
            </div>
        </div>
    )
}