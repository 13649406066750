
export const PLANTING_STATUS = {
    PLANNED: "planned",
    SOWN: "sown",
    TRANSPLANTED: "transplanted",
    HARVESTABLE: "harvestable",
    HARVESTED: "harvested"
}

export const PLANTING_STATUS_LABELS = {
    [PLANTING_STATUS.PLANNED]: "Gepland",
    [PLANTING_STATUS.SOWN]: "Gezaaid",
    [PLANTING_STATUS.TRANSPLANTED]: "Verplant",
    [PLANTING_STATUS.HARVESTED]: "Geoogst"
}

export const GENERIC_ERROR_MESSAGE = "Sorry, er ging iets fout. Controleer wat je hebt ingevulden probeer het (later) opnieuw."

export const monthOptions = [
        {value: 1, label: 'Jan'},
        {value: 2, label: 'Feb'},
        {value: 3, label: 'Mrt'},
        {value: 4, label: 'Apr'},
        {value: 5, label: 'Mei'},
        {value: 6, label: 'Jun'},
        {value: 7, label: 'Jul'},
        {value: 8, label: 'Aug'},
        {value: 9, label: 'Sep'},
        {value: 10, label: 'Oct'},
        {value: 11, label: 'Nov'},
        {value: 12, label: 'Dec'},
        {value: 0, label: '-'},
    ]