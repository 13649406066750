import React from "react";
import {Card, Image} from "antd";
import dayjs from 'dayjs';

const UpdateCard = ({update, children}) => {
    return (
        <Card style={{backgroundColor: 'white'}} className={"mar-b-1 mar-r-1"} key={update.id}>
            <h2>{update.title}</h2>
            <p>{dayjs(update.posted_at).fromNow()}</p>
            <Image src={update.image} alt={update.title}
                   style={{
                       width: "100%",
                       height: "auto",
                       aspectRatio: "1 / 1", // Ensures square ratio
                       objectFit: "cover", // Makes the image fill the frame
                   }}/>
            <p className={"mar-t-1"}>{update.description.split('\n').map(l => {
                return <span key={l}>{l}<br/></span>
            })}</p>
            {children}
        </Card>
    )
}

export default UpdateCard;