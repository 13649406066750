import React from "react";
import {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, message, Row} from "antd";
import api from "../../api";
import routes from "../../routes";

const CompanyProfile = () => {

    const [company, setCompany] = useState({})
    const [uploadedLogo, setUploadedLogo] = useState(null)
    const [uploadedHeader, setUploadedHeader] = useState(null)
    const [form] = Form.useForm()

    const forbiddenSlugs = routes.map(function (route) {
        return route.path.split("/")[1]
    });

    function handleHeaderUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedHeader);
    }

    function handleLogoUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedLogo);
    }

    useEffect(() => {
        api.getMyCompany().then(setCompany)
    }, []);

    function saveCompanyProfile() {

        const regex = /^[a-zA-Z0-9\-]+$/

        if (forbiddenSlugs.includes(form.getFieldValue("slug")) || !regex.test(form.getFieldValue("slug"))) {
            message.error("Deze URL is niet toegestaan. Kies een andere. Alleen kleine letters, cijfers en streepjes zijn toegestaan.")
            return
        }

        form.validateFields().then(values => {
            const updatedCompany = {
                name: values.name,
                slug: values.slug,
                city: values.city,
                is_public: values.is_public ? true : false,
                is_published: values.is_published ? true : false,
                description: values.description,
                logo: uploadedLogo,
                header_image: uploadedHeader
            }
            api.updateCompany(updatedCompany).then(() => {
                api.getMyCompany().then(setCompany)
                message.success("Je profiel is opgeslagen!")
            }).catch((error) => {
                if (error.response.data.detail && error.response.data.detail === "SlugAlreadyInUse") {
                    message.error("Deze URL is al in gebruik. Kies een andere.")
                }
                return
            })
        })
    }

    useEffect(() => {
        form.setFieldValue("name", company.name)
        form.setFieldValue("slug", company.slug)
        form.setFieldValue("description", company.description)
        form.setFieldValue("is_public", company.is_public)
        form.setFieldValue("is_published", company.is_published)
        form.setFieldValue("city", company.city)
        setUploadedHeader(company.header_image)
        setUploadedLogo(company.logo)
    }, [company]);

    return (
        <>

            <Row>
                <Col md={12} xs={24}>
                    <h1>Profiel instellingen</h1>
                </Col>
                <Col className={"text-right"} md={12} xs={24}>
                    <Button type={"primary"} size={"large"} href={"/" + company.slug} target={"_blank"}>
                        Bekijk profiel
                    </Button>
                </Col>
            </Row>
            <Form layout={"vertical"} form={form}>
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={12}>
                        <h2>Algemene informatie</h2>
                        <Form.Item label={"Naam"} name={"name"}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={"Plaats"} name={"city"}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={"URL"} name={"slug"}>
                            <Input addonBefore={"https://oogst.app/"}/>
                        </Form.Item>
                        <Form.Item name={"is_published"} valuePropName={"checked"} label={"Profiel publiceren"}
                                   tooltip={"Als je dit aanvinkt zal je profiel worden gepubliceerd op internet. Doe je dit niet? Dan zal alleen jij je profiel kunnen bekijken als je ingelogd bent."}>
                            <Checkbox>Mijn profiel publiceren</Checkbox>
                        </Form.Item>
                        <Form.Item name={"is_public"} valuePropName={"checked"} label={"Profiel publiek"}
                                   tooltip={"Als je dit aanvinkt, wordt je getoond op de kaart met tuinderijen in Nederland. We publiceren niet je exacte adres, enkel in welke plaats je zit."}>
                            <Checkbox>Ja, toon mijn op de kaart met tuinderijen!</Checkbox>
                        </Form.Item>
                        <Form.Item name={"description"} label={"Beschrijving / bio"}
                                   tooltip={"Hier kun je een leuke introductietekst schrijven. Dit is de eerste tekst die iemand zal zien."}>
                            <Input.TextArea autoSize={{
                                minRows: 4,
                                maxRows: 999
                            }}/>
                        </Form.Item>

                    </Col>

                    <Col span={12}>
                        <h2>Afbeeldingen</h2>
                        <Form.Item label={"Logo"}>
                            <Row>
                                <Col className={"mar-b-1"} xs={24} md={8}>{uploadedLogo &&
                                    <img src={uploadedLogo} style={{width: "90%"}} alt={uploadedLogo}/>}</Col>
                                <Col className={"mar-b-1"} xs={24} md={8}>
                                    <input type="file" onChange={handleLogoUpload}/>
                                </Col>
                                <Col className={"mar-b-1"} xs={24} md={8}>
                                    {uploadedLogo && (
                                        <Button size={"small"} onClick={() => setUploadedLogo(null)} danger>
                                            Logo verwijderen
                                        </Button>
                                    )}
                                </Col>
                            </Row>


                        </Form.Item>
                        <Form.Item label={"Banner"}
                                   tooltip={"De banner is een grote afbeelding die wordt getoond na je logo. Hier kun je bijvoorbeeld een vette foto uploaden van je tuin!"}>
                            <Row>
                                <Col className={"mar-b-1"} xs={24} md={8}>
                                    {uploadedHeader &&
                                        <img src={uploadedHeader} style={{width: "90%"}} alt={uploadedHeader}/>}
                                </Col>
                                <Col className={"mar-b-1"} xs={24} md={8}>
                                    <input type="file" onChange={handleHeaderUpload}/>
                                </Col>
                                <Col className={"mar-b-1"} xs={24} md={8}>
                                    {uploadedHeader && (
                                        <Button size={"small"} onClick={() => setUploadedHeader(null)} danger>
                                            Banner verwijderen
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"text-center"}>
                        <Form.Item>
                            <Button size={"large"} type={"primary"} onClick={() => saveCompanyProfile()}>
                                Profiel opslaan
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </>
    )
}

export default CompanyProfile;