import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import api from "../../api";
import {Alert, Button, Card, Col, Form, Image, Input, message, Row, Spin} from "antd";
import './style.css'
import UpdateCard from "../../Components/UpdateCard";

const Profile = () => {

    const {slug} = useParams()
    const [company, setCompany] = useState({});
    const [myCompany, setMyCompany] = useState({});

    useEffect(() => {

        if (api.isLoggedIn()) {
            api.getMyCompany().then(setMyCompany)
        }

        api.getCompanyBySlug(slug).then((c) => {
            setCompany(c)
            if(!c.is_published && !api.isLoggedIn()){
                document.location = '/404'
            }
        }).catch((error) => {
            if (error.response.data.detail === "CompanyNotFound") {
                document.location = '/404'
            }
        })
    }, [slug]);

    const subscribe = (values) => {
        api.subscribeToCompany(company.id, values.name, values.email).then(() => {
            message.success("Je bent ingeschreven voor de updates!")
        })
    }

    if (!company.id) {
        return <Spin/>
    }


    return (
        <div className={"text-center"}>

            {(myCompany?.id === company?.id && !myCompany.is_published) && (
                <Alert
                    description={"Je profiel pagina is nog niet gepubliceerd. Alleen jij kan deze pagina zien als je ingelogd bent."}></Alert>
            )}

            {company?.logo && (
                <div className={"profile-logo"}>
                    <img src={company?.logo} alt={company?.name}/>
                </div>
            )}

            {company?.header_image && (
                <div className={"profile-header"} style={{backgroundImage: `url(${company?.header_image})`}}>
                </div>
            )}

            <div className={"profile-bio mar-t-2"}>
                <h1>Over {company?.name}</h1>
                <p className={"mar-t-1 mar-b-1"} style={{fontSize: '1.5em'}}>
                    {company?.description?.split('\n').map(line => {
                        return <>{line}<br/></>
                    })}
                </p>
                {!company?.description && (
                    <p>Er is nog geen beschrijving geplaatst.</p>
                )}
            </div>

            {company?.users?.length > 1 && (
                <div className={"mar-t-2"}>
                    <h1>Ons Team</h1>
                    <Row justify={"center"}>
                        {company?.users?.map(member => (
                            <Col key={member.id} md={2} xs={12}>
                                <h2>{member.name}</h2>
                                <img src={member.avatar ? member.avatar : '/static/img/empty-avatar.png'}
                                     alt={member.name} style={{width: '150px', borderRadius: '50%'}}/>
                            </Col>
                        ))}
                    </Row>
                </div>
            )}

            {company?.updates && (
                <div className={"mar-t-2"}>
                    <h1>Inschrijven</h1>
                    <p>Mis niks! Vul hieronder je emailadres in en je ontvangt alle updates per email van {company?.name}</p>
                    <Row justify={"center"}>
                        <Col xs={24} md={8}>
                            <Form onFinish={subscribe}>
                                <Form.Item name={"name"} rules={[
                                    {
                                        required: true,
                                        message: 'Vul je naam in',
                                    }
                                ]}>
                                    <Input size={"large"} type={"text"} placeholder={"Vul je naam in"}></Input>
                                </Form.Item>
                                <Form.Item name={"email"} rules={[
                                    {
                                        required: true,
                                        message: 'Vul een (geldig) emailadres in',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Vul een (geldig) emailadres in',
                                    }
                                ]}>
                                    <Input size={"large"} type={"email"} placeholder={"Vul je emailadres in"}></Input>
                                </Form.Item>
                                <Form.Item>
                                    <Button size={"large"} htmlType={"submit"} type={"primary"}>Inschrijven</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                    <h1>Updates</h1>
                    <h2>Hieronder vind je de updates van {company?.name}</h2>
                    <Row>
                        {company?.updates.map(update => (
                            <Col xs={24} sm={8}>
                                <UpdateCard update={update}></UpdateCard>
                            </Col>
                        ))}
                    </Row>

                    {company?.updates.length === 0 && (
                        <p>Er zijn nog geen updates geplaatst. :-( Kom later terug.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Profile;