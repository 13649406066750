import React, {useEffect, useState} from 'react'
import {Alert, Button, Col, Form, Input, message, Row} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

export default function Welcome() {

    const [eventSend, setEventSend] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (eventSend) {
            return
        }
        window.gtag('event', 'lead', {'send_to': 'AW-939836725/ZCERCOuLqv8BELWKk8AD'});
        setEventSend(true)
    });

    return (
        <Row justify={"center"}>
            <Col className={"text-center"} md={12} xs={24}>
                <h1>Welkom!</h1>
                <p>Welkom bij de club van oogsters!</p>

                <h2>Wat wil je gaan oogsten?</h2>
                <Form form={form} layout={"horizontal"} onFinish={(values) => {
                    navigate('/crops?search=' + values.query)
                }}>
                    <Form.Item label={""} name={"query"}>
                        <Input size={"large"} placeholder={"Tomaat"} />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={"submit"} type={"primary"} size={"large"}>Volgende</Button>
                    </Form.Item>
                </Form>


                <h1>Kom je er toch niet uit?</h1>
                <p>Rechtsboven staat een knop, waarmee je een korte instructievideo kunt kijken over dat deel van
                    Oogst.app. Hij ziet er zo uit:</p>
                <Button
                    onClick={() => message.info("Dit is een voorbeeldknop ;-) Klik op de knop rechtsboven in het menu om hem in actie te zien")}>
                    <PlaySquareOutlined/> Hoe werkt dit?
                </Button>

            </Col>


        </Row>
    )
}