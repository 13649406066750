import {useLocation} from "react-router-dom";
import {Button, message, Modal} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import "./style.css"
import {useState} from "react";

function HeaderHelpLink() {

    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [videoSource, setVideoSource] = useState(null);

    const videos = [
        {
            pathname: "/payment/success",
            loom: "https://www.loom.com/embed/aa3f9270ed7c4805b01c2245040ccb9b?sid=a0d5b6cd-5685-4441-891a-9dee29e5c6d1"
        },
        {
            pathname: "/welcome",
            loom: "https://www.loom.com/embed/aa3f9270ed7c4805b01c2245040ccb9b?sid=a0d5b6cd-5685-4441-891a-9dee29e5c6d1"
        },
        {
            pathname: "/gardens",
            loom: "https://www.loom.com/embed/12184292514647fcbf5eb31f416357f9?sid=dd666e40-e7d1-4aa5-bb59-d54f7aed35b1"
        },
        {
            pathname: "/planner",
            loom: "https://www.loom.com/embed/812a9cb9a96a4b30969249c8bc470ffb?sid=c0217e20-4db0-417c-a8a0-31e33954684a"
        },
        {
            pathname: "/profile",
            loom: "https://www.loom.com/embed/b09ce9ccc65a47eb89359a8f308e91e1?sid=3e309209-6dae-4006-8eaa-fc3d7d98ffe5"
        },
        {
            pathname: "/crops",
            loom: "https://www.loom.com/embed/89bf590b443743ea8e5e5a7d7072364c?sid=13c099e6-0d62-4efb-920c-08ca95f317f2"
        },
        {
            pathname: "/updates",
            loom: "https://www.loom.com/embed/ee5dff101bda4a80af04da97da41d2bc?sid=0bc67ad8-69b8-442f-9a77-a15a5c1ef7c5"
        }
    ]



    function showVideo(){
        const video = videos.filter((video) => {
            return location.pathname.startsWith(video.pathname)
        })

        if(video.length === 0) {
            message.info("Sorry, we hebben voor deze pagina nog geen video opgenomen. ")
            return
        }

        setModalOpen(true)
        setVideoSource(video[0]?.loom)
    }

    const video = videos.filter((video) => {
        return location.pathname.startsWith(video.pathname)
    })

    if(video.length === 0) {
        return null
    }

    return (
        <>
            <Modal open={modalOpen} width={"90%"} destroyOnClose={true} onCancel={() => setModalOpen(false)} footer={(
                <Button onClick={() => setModalOpen(false)}>Sluiten</Button>
            )}>

                <div className={"help-loom-wrap"}>
                    {(videoSource && (
                        <iframe
                            title={"Demo"}
                            src={videoSource}
                            frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                        ></iframe>
                    ))}
                </div>
            </Modal>
            <div className={"header-help-link-wrap"}>
                <Button onClick={() => showVideo()}>
                    <PlaySquareOutlined/> Hoe werkt dit?
                </Button>
            </div>
        </>
    )
}

export default HeaderHelpLink