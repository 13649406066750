import React, {useEffect, useState} from 'react';
import api from "../../api";
import {Button, Card, Checkbox, Col, Image, Modal, Row, Table} from "antd";
import dayjs from "dayjs";
import './style.css';
import {useNavigate} from "react-router-dom";
import UpdateCard from "../../Components/UpdateCard";
import LogUpdateModal from "../../Components/LogUpdateModal";

export default function Updates() {

    const [updates, setUpdates] = useState([]);
    const [createUpdateModalOpen, setCreateUpdateModalOpen] = useState(false);

    useEffect(() => {
        api.getAllUpdates().then(setUpdates);
    }, []);

    const deleteUpdate = (update) => {
        api.deleteUpdate(update.id).then(() => {
            setUpdates(updates.filter(u => u.id !== update.id));
        });
    }

    return (
        <div>
            <LogUpdateModal open={createUpdateModalOpen} onCancel={() => {
                setCreateUpdateModalOpen(false)
                api.getAllUpdates().then(setUpdates);
            }}/>
            <Row>
                <Col xs={24} md={12}>
                    <h1>Updates</h1>
                    <p>Hier vind je alle updates die op je profiel staan.</p>
                </Col>
                <Col xs={24} md={12} className={"text-right"}>
                    <Button type={"primary"} size="large" onClick={() => setCreateUpdateModalOpen(true)}>Nieuwe update posten</Button>
                </Col>
            </Row>

            <Row gutter={12}>
                {updates.map(update => (
                    <Col xs={24} sm={6} key={`update${update.id}`}>
                        <UpdateCard update={update} children={<>
                            <Button size={"small"} danger className={"mar-b-1"} onClick={() => deleteUpdate(update)}>
                                Verwijderen
                            </Button>
                        </>}></UpdateCard>

                    </Col>
                ))}
            </Row>
        </div>
    );
}