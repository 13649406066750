import React, {useEffect, useState} from 'react'
import {Alert, Button, Col, message, Row} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import {useSearchParams} from "react-router-dom";

export default function PaymentSuccess() {

    const [eventSend, setEventSend] = useState(false)
    const [searchParams] = useSearchParams();


    useEffect(() => {
        if (eventSend) {
            return
        }
        window.gtag('event', 'purchase', {
            'send_to': 'AW-939836725/ZCERCOuLqv8BELWKk8AD',
            'value': searchParams.get('a'),
            'transaction_id': searchParams.get('id'),
            'currency': 'EUR',
            'items': [{
                'item_id': 1,
                'item_name': 'Oogst.app Moestuinier'
            }]
        });
        setEventSend(true)
    });

    return (
        <Row>
            <Col className={"text-center"} span={24}>
                <h1>Betaling gelukt!</h1>
                <Alert type={"success"}
                       description={"Je betaling is gelukt! Je kan nu gebruik maken van Oogst.app!"}></Alert>
                <br/>
                <h1>Happy Gardening!</h1>
                <img style={{maxWidth: '100%'}} src={"/static/img/happy-gardening.webp"}/>
                <br/><br/>
                <h1>Hoe nu verder?</h1>
                <p>Rechtsboven staat een knop, waarmee je een korte instructievideo kunt kijken over dat deel van
                    Oogst.app. Hij ziet er zo uit:</p>
                <Button
                    onClick={() => message.info("Dit is een voorbeeldknop ;-) Klik op de knop rechtsboven in het menu om hem in actie te zien")}>
                    <PlaySquareOutlined/> Hoe werkt dit?
                </Button>

            </Col>


        </Row>
    )
}