import React, {useEffect, useState} from 'react'
import {Col, Form, Input, Row, Select, Table} from "antd";
import {monthOptions} from "../../constants";
import api from "../../api";

const SeedDatabase = () => {

    const [products, setProducts] = useState([]);

    useEffect(() => {
        api.getProductSearchFilters().then(console.log)
        api.getAllProducts().then(setProducts)
    }, []);

    return (
        <div>
            <h1>Zaden Zoeker</h1>
            <Row>
                <Col span={20}>
                    <h2>Zoekresultaten</h2>
                    <Table pagination={false} columns={[
                        {
                            title: 'Naam',
                            render(item) {
                                return `${item.name} ${item.variety}`
                            },
                            key: 'name',
                        },
                        {
                            title: 'Merk',
                            dataIndex: 'supplier',
                            key: 'supplier',
                        }
                    ]} dataSource={products}></Table>
                </Col>
                <Col span={4}>
                    <h2>Filters</h2>
                    <Form layout={"vertical"}>
                        <Form.Item label={"Zoekterm"}>
                            <Input type="text" placeholder="Zoek op naam, variant en meer"/>
                        </Form.Item>
                        <Form.Item label={"Zaaien in maand"}>
                            <Select options={monthOptions}/>
                        </Form.Item>
                        <Form.Item label={"Uitplanten in"}>
                            <Select options={monthOptions}/>
                        </Form.Item>
                        <Form.Item label={"Oogsten in"}>
                            <Select options={monthOptions}/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default SeedDatabase