import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, Form, Input, Modal, Row, Switch, Upload} from "antd";
import api from "../../api";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import TextArea from "antd/lib/input/TextArea";

dayjs.extend(relativeTime);

export default function Calendar() {
    const [plantings, setPlantings] = useState([])
    const [selectedPlanting, setSelectedPlanting] = useState(null)
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [form] = Form.useForm()

    useEffect(() => {
        api.plantings().then(setPlantings)
    }, [])

    function toggleCheckbox(field, planting) {
        const newPlanting = {...planting}
        newPlanting[field] = !newPlanting[field]
        api.updatePlanting(planting.id, newPlanting).then(() => {
            api.plantings().then(setPlantings)
        })
    }

    function closeAll(){
        form.resetFields()
        setUploadedFile(null)
        setSelectedPlanting(null)
    }

    function logUpdate() {
        form.validateFields().then(values => {
            const update = {
                title: values.title,
                description: values.description,
                image: uploadedFile,
                send_update: values.send_update
            }
            api.logUpdate(selectedPlanting.id, update).then(() => {
                closeAll()
            })
        })
    }

    const gutter = [
        {xs: 8, sm: 8, md: 24, lg: 32},
        {xs: 8, sm: 8, md: 24, lg: 32}
    ]

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    return (
        <>
            <h1>Calendar</h1>
            <Modal open={selectedPlanting} onCancel={() => closeAll()} footer={(
                <Button type={"primary"} onClick={logUpdate}>Post update</Button>
            )}>
                <h1>Update</h1>
                <Form form={form} labelCol={24} layout={"vertical"}>
                    <Form.Item name="title" label="Name of the bed" labelAlign="left">
                        <Input style={{width: "100%"}}/>
                    </Form.Item>
                    <Form.Item name="description" label={"Update description"}>
                        <TextArea style={{width: "100%"}}/>
                    </Form.Item>
                    <Form.Item label={"Image"}>
                        {uploadedFile && <img src={uploadedFile} style={{width: "50%"}} alt={uploadedFile}/>}
                        <input type="file" onChange={handleUpload}/>
                    </Form.Item>
                </Form>
            </Modal>
            {plantings.map(planting => {
                return (
                    <Row gutter={gutter} key={planting.id} className={"crop-row"}>
                        <Col md={2} xs={6}><img src={planting.crop.image} alt={planting.crop.name}/></Col>
                        <Col md={8} xs={18}>
                            <h2>{planting.plants_per_row * planting.rows}x {planting.crop.name}<br/>Bed: {planting.bed.name}
                            </h2>
                            <p>
                                Rijen: {planting.rows}
                                <br/>Planten per rij: {planting.plants_per_row}
                            </p>
                        </Col>
                        <Col md={8} xs={12}>
                            <p>
                                Voorzaaien
                                <br/>{dayjs(planting.sow_at).from(dayjs())}
                            </p>
                            <p>
                                Uitplanten
                                <br/>{dayjs(planting.transplant_at).from(dayjs())}
                            </p>
                            <p>

                                Oogsten
                                <br/>{dayjs(planting.harvest_at).from(dayjs())}
                            </p>
                        </Col>
                        <Col md={4} xs={12}>
                            <div>
                                <Checkbox checked={planting.sown}
                                          onChange={() => toggleCheckbox('sown', planting)}>Gezaaid</Checkbox>
                            </div>
                            <div>
                                <Checkbox checked={planting.transplanted}
                                          onChange={() => toggleCheckbox('transplanted', planting)}>Uitgepland</Checkbox>
                            </div>
                            <div>
                                <Checkbox checked={planting.harvested}
                                          onChange={() => toggleCheckbox('harvest', planting)}>Geoogst</Checkbox>
                            </div>
                            <div>
                                <br/>
                                <Button type={"primary"} onClick={() => setSelectedPlanting(planting)}>Log
                                    update</Button>
                            </div>
                        </Col>
                    </Row>
                )
            })}
            <Row>
                <Col></Col>
            </Row>
        </>
    )
}