import {Button, Form, Input, Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import api from "../../api";

export default function LogUpdateModal({planting, open, onCancel}) {

    const [form] = Form.useForm()
    const [uploadedFile, setUploadedFile] = React.useState(null);
    function logUpdate() {
        console.log(planting)
        form.validateFields().then(values => {
            const update = {
                planting_id: planting ? planting.id : null,
                title: values.title,
                description: values.description,
                image: uploadedFile,
                send_update: values.send_update
            }
            api.logUpdate(update).then(() => {
                onCancel()
                setUploadedFile(null);
                form.resetFields();
            })
        })
    }

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    return (

        <Modal open={open} onCancel={onCancel} footer={(
            <Button type={"primary"} onClick={logUpdate} disabled={uploadedFile === null}>Update posten</Button>
        )}>
            <h1>Update posten</h1>
            <Form form={form} labelCol={24} layout={"vertical"}>
                <Form.Item name="title" label="Titel van update" labelAlign="left">
                    <Input style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name="description" label={"Kleine beschrijving"}>
                    <TextArea autoSize={{
                        minRows: 3,
                        maxRows: 999
                    }} style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item label={"Afbeelding / foto"}>

                    <input type="file" onChange={handleUpload}/>

                    {uploadedFile && <img src={uploadedFile} style={{width: "50%"}} alt={uploadedFile}/>}
                </Form.Item>
            </Form>
        </Modal>
    )
}